import vtulEnums from '../../../cross/index';
import { permissions } from '../../../cross/src/roles.enum';

const Enum = require('enum');

const { planCapabilities } = vtulEnums.enum.planCapabilities;
const { frequencies, parametersToDeleteFiles } = vtulEnums.enum.settings;
const { bitLockerAutoReseal: bitLockerAutoResealEnum } = vtulEnums.enum.bitlocker;
const { notifications: remoteDesktopNotifications } = vtulEnums.enum.remoteDesktop;
const { controlPanel } = vtulEnums.enum.device;

const parentSettings = {
  localAdministratorPasswordSection: {
    id: 'localAdministratorPasswordSection',
    name: 'Operating system local administrator',
    requestedCapabilities: [planCapabilities.LOCAL_PASSWORD_MANAGER.value],
    position: 0,
  },
  biosPasswordSection: {
    id: 'biosPasswordSection',
    name: 'BIOS or UEFI administrator',
    requestedCapabilities: [planCapabilities.BIOS_PASSWORD_MANAGER.value],
    position: 1,
  },
  amtManagementSection: {
    id: 'amtManagementSection',
    name: 'Intel® vPro Active Management Technology',
    requestedCapabilities: [planCapabilities.AMT_MANAGEMENT.value],
    position: 2,
  },
  remoteDesktopSection: {
    id: 'remoteDesktopSection',
    name: 'Remote desktop',
    requestedCapabilities: [planCapabilities.REMOTE_DESKTOP.value],
    position: 3,
  },
  geolocationSection: {
    id: 'geolocationSection',
    name: 'Geolocation',
    requestedCapabilities: [planCapabilities.LOCATIONS.value],
    position: 4,
  },
  bootOrderSection: {
    id: 'bootOrderSection',
    name: 'Boot order',
    requestedCapabilities: [planCapabilities.BOOT_ORDER.value],
    position: 5,
  },
  manageOsUpdates: {
    id: 'manageOsUpdates',
    name: 'Manage OS Updates',
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
    ],
    position: 6,
  },
  inventorySection: {
    id: 'inventorySection',
    name: 'Inventory',
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
    ],
    position: 7,
  },
  cleanFoldersSection: {
    id: 'cleanFoldersSection',
    name: 'Maintenance',
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
    ],
    position: 8,
  },
  gpo: {
    id: 'gpo',
    name: 'Group policies (GPOs)',
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
    ],
    position: 9,
  },
  freezeSection: {
    id: 'uwf',
    name: 'Freeze',
    requestedCapabilities: [
      planCapabilities.UWF.value,
    ],
    position: 10,
  },
  // deviceAppearance: {
  //   id: 'deviceAppearance',
  //   name: 'Appearance',
  //   requestedCapabilities: [
  //     planCapabilities.DEVICES_MANAGEMENT.value,
  //   ],
  //   position: 10,
  // },
  boardgentUpdatesSection: {
    id: 'boardgentUpdatesSection',
    name: 'Boardgent updates',
    requestedCapabilities: [
      planCapabilities.FIXED_AGENT_VERSION.value,
    ],
    position: 11,
  },
};

export default new Enum({
  ...parentSettings,
  manageApps: {
    id: 'manageApps',
    name: 'Manage applications',
    enabled: true,
    tooltip: '',
    setupTime: false,
    addApps: true,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: true,
    available: true,
    adjustableTime: false,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value],
    parent: parentSettings.inventorySection.id,
  },
  requestUserConsentOS: {
    id: 'requestUserConsentOS',
    name: 'Request remote desktop user consent',
    enabled: true,
    tooltip: '',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    adjustableTime: false,
    dropdownOptions: remoteDesktopNotifications.enums.map((x) => x.value),
    requestedCapabilities: [planCapabilities.REMOTE_DESKTOP.value],
    default: remoteDesktopNotifications.REQUEST_USER_CONSENT.value,
    parent: parentSettings.remoteDesktopSection.id,
  },
  automaticallyProvisionAMT: {
    id: 'automaticallyProvisionAMT',
    name: 'Enable Intel® vPro AMT automatically',
    enabled: true,
    tooltip: 'Keep Intel® vPro AMT always provisioned on all devices that support this technology',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    adjustableTime: false,
    requestedCapabilities: [
      planCapabilities.AMT_MANAGEMENT.value,
    ],
    parent: parentSettings.amtManagementSection.id,
  },
  resetPassword: {
    id: 'resetPassword',
    name: 'Reset password',
    enabled: true,
    tooltip: 'Administration and control of the access passwords',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: true,
    available: true,
    adjustableTime: false,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value],
    parent: parentSettings.inventorySection.id,
  },
  windowsUpdate: {
    id: 'windowsUpdate',
    name: 'Keep the operating system update',
    enabled: true,
    tooltip: '',
    setupTime: true,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    adjustableTime: false,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.UPDATES_INVENTORY.value],
    parent: parentSettings.manageOsUpdates.id,
  },
  diskEncryption: {
    id: 'diskEncryption',
    name: 'Disk encryption',
    enabled: true,
    tooltip: 'Secure your device information',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: true,
    available: true,
    adjustableTime: false,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value],
    parent: parentSettings.inventorySection.id,
  },
  systemOptimization: {
    id: 'systemOptimization',
    name: 'System optimization',
    enabled: true,
    tooltip: 'Keep your device always optimized',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: true,
    available: true,
    adjustableTime: false,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value],
    parent: parentSettings.inventorySection.id,
  },
  licensingOptimization: {
    id: 'licensingOptimization',
    name: 'Licensing optimization',
    enabled: true,
    tooltip: 'Management and optimization of licensed software',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: true,
    available: true,
    adjustableTime: false,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value],
    parent: parentSettings.inventorySection.id,
  },
  antivirus: {
    id: 'antivirus',
    name: 'Antivirus',
    enabled: true,
    tooltip: 'Activation and running of antivirus to protect your device',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: true,
    available: true,
    adjustableTime: false,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SYSTEM_STATUS.value],
    parent: parentSettings.inventorySection.id,
  },
  firewall: {
    id: 'firewall',
    name: 'Firewall',
    enabled: true,
    tooltip: 'Prevent and protect your private network from intrusions or attacks from other networks',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: true,
    available: true,
    adjustableTime: false,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value],
    parent: parentSettings.inventorySection.id,
  },
  geolocation: {
    id: 'geolocation',
    name: 'Update geolocation automatically',
    enabled: true,
    tooltip: '',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    adjustableTime: true,
    defaultFrequency: frequencies.FIVE_MINUTES.value.id,
    minFrequency: frequencies.FIVE_MINUTES.value.minutes,
    maxFrequency: frequencies.TWENTY_FOUR_HOURS.value.minutes,
    requestedCapabilities: [planCapabilities.LOCATIONS.value],
    parent: parentSettings.geolocationSection.id,
  },
  turnWifiOnTemporarily: {
    id: 'turnWifiOnTemporarily',
    name: 'Allow Boardgent to temporarily turn on the Wi-Fi chip to improve the location accuracy',
    enabled: true,
    tooltip: '',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    adjustableTime: false,
    requestedCapabilities: [planCapabilities.LOCATIONS.value],
    parent: parentSettings.geolocationSection.id,
  },
  locationBounds: {
    id: 'locationBounds',
    name: 'Enable geo-fences',
    enabled: false,
    tooltip: '',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    adjustableTime: false,
    requestedCapabilities: [planCapabilities.GEOFENCING.value],
    requestedPermission: [permissions.GEOFENCING.value.dbValue],
    parent: parentSettings.geolocationSection.id,
  },
  softwareInventory: {
    id: 'softwareInventory',
    name: 'Update software inventory automatically ',
    enabled: true,
    tooltip: '',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    adjustableTime: true,
    defaultFrequency: frequencies.TWENTY_FOUR_HOURS.value.id,
    minFrequency: frequencies.ONE_HOUR.value.minutes,
    maxFrequency: frequencies.TWENTY_FOUR_HOURS.value.minutes,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.SOFTWARE_INVENTORY.value],
    parent: parentSettings.inventorySection.id,
  },
  hardwareInventory: {
    id: 'hardwareInventory',
    name: 'Update hardware inventory automatically ',
    enabled: true,
    tooltip: '',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    adjustableTime: true,
    defaultFrequency: frequencies.TWENTY_FOUR_HOURS.value.id,
    minFrequency: frequencies.ONE_HOUR.value.minutes,
    maxFrequency: frequencies.TWENTY_FOUR_HOURS.value.minutes,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.HARDWARE_INVENTORY.value],
    parent: parentSettings.inventorySection.id,
  },
  computerInformation: {
    id: 'computerInformation',
    name: 'Update computer information automatically',
    enabled: true,
    tooltip: '',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: false,
    adjustableTime: true,
    defaultFrequency: frequencies.ONE_HOUR.value.id,
    minFrequency: frequencies.ONE_HOUR.value.minutes,
    maxFrequency: frequencies.TWENTY_FOUR_HOURS.value.minutes,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value],
    parent: parentSettings.inventorySection.id,
  },
  networkInterfaces: {
    id: 'networkInterfaces',
    name: 'Update network interfaces automatically',
    enabled: true,
    tooltip: '',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    adjustableTime: true,
    defaultFrequency: frequencies.TWENTY_FOUR_HOURS.value.id,
    minFrequency: frequencies.ONE_HOUR.value.minutes,
    maxFrequency: frequencies.TWENTY_FOUR_HOURS.value.minutes,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
    parent: parentSettings.inventorySection.id,
  },
  listSystemUpdates: {
    id: 'listSystemUpdates',
    name: 'Update list of installed system updates automatically',
    enabled: true,
    tooltip: '',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    adjustableTime: true,
    defaultFrequency: frequencies.TWENTY_FOUR_HOURS.value.id,
    minFrequency: frequencies.ONE_HOUR.value.minutes,
    maxFrequency: frequencies.TWENTY_FOUR_HOURS.value.minutes,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.UPDATES_INVENTORY.value],
    parent: parentSettings.inventorySection.id,
  },
  windowsAdminPassword: {
    id: 'windowsAdminPassword',
    enabled: false,
    tooltip: '',
    setupTime: false,
    canSwitch: false,
    withoutSwitch: false,
    adjustableTime: true,
    defaultFrequency: frequencies.ONE_HOUR.value.id,
    minFrequency: frequencies.THIRTY_MINUTES.value.minutes,
    maxFrequency: frequencies.TWENTY_FOUR_HOURS.value.minutes,
    isAdvancedPlan: false,
    available: true,
    requestedCapabilities: [
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
    ],
    parent: parentSettings.localAdministratorPasswordSection.id,
  },
  bootOrder: {
    id: 'bootOrder',
    name: 'Manage boot order',
    enabled: false,
    tooltip: '',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    requestedCapabilities: [
      planCapabilities.BOOT_ORDER.value,
    ],
    parent: parentSettings.bootOrderSection.id,
  },
  biosPassword: {
    id: 'biosPassword',
    enabled: false,
    tooltip: '',
    setupTime: false,
    canSwitch: false,
    withoutSwitch: false,
    adjustableTime: true,
    defaultFrequency: frequencies.ONE_HOUR.value.id,
    minFrequency: frequencies.THIRTY_MINUTES.value.minutes,
    maxFrequency: frequencies.TWENTY_FOUR_HOURS.value.minutes,
    isAdvancedPlan: false,
    available: true,
    requestedCapabilities: [planCapabilities.BIOS_PASSWORD_MANAGER.value],
    parent: parentSettings.biosPasswordSection.id,
  },
  updateUsers: {
    id: 'updateUsers',
    name: 'Update users history automatically',
    enabled: true,
    tooltip: '',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    adjustableTime: true,
    defaultFrequency: frequencies.TWENTY_FOUR_HOURS.value.id,
    minFrequency: frequencies.ONE_HOUR.value.minutes,
    maxFrequency: frequencies.TWENTY_FOUR_HOURS.value.minutes,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
    parent: parentSettings.inventorySection.id,
  },
  agentUpdate: {
    id: 'agentUpdate',
    name: 'Check for Boardgent Agent updates automatically every ',
    enabled: true,
    tooltip: '',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: false,
    adjustableTime: true,
    defaultFrequency: frequencies.ONE_HOUR.value.id,
    minFrequency: frequencies.ONE_HOUR.value.minutes,
    maxFrequency: frequencies.TWENTY_FOUR_HOURS.value.minutes,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value,
      planCapabilities.LOCATIONS.value,
      planCapabilities.SOFTWARE_INVENTORY.value,
      planCapabilities.HARDWARE_INVENTORY.value,
      planCapabilities.UPDATES_INVENTORY.value,
      planCapabilities.SYSTEM_STATUS.value,
      planCapabilities.BIOS_PASSWORD_MANAGER.value,
      planCapabilities.AMT_MANAGEMENT.value,
      planCapabilities.LOCAL_PASSWORD_MANAGER.value,
      planCapabilities.REMOTE_DESKTOP.value,
      planCapabilities.DISK_ENCRYPTION.value,
      planCapabilities.BOOT_ORDER.value,
    ],
    parent: parentSettings.inventorySection.id,
  },
  agentVersion: {
    id: 'agentVersion',
    name: 'Maximum agent update',
    enabled: true,
    setupTime: false,
    canSwitch: false,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    selectedVersion: '0',
    requestedCapabilities: [planCapabilities.FIXED_AGENT_VERSION.value],
    parent: parentSettings.boardgentUpdatesSection.id,
  },
  bitLockerAutoReseal: {
    id: 'bitLockerAutoReseal',
    name: 'Reset BitLocker recovery key automatically after is used.',
    enabled: false,
    tooltip: '',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    dropdownOptions: bitLockerAutoResealEnum.enums.map((x) => x.value),
    requestedCapabilities: [planCapabilities.DISK_ENCRYPTION.value],
    parent: parentSettings.gpo.id,
  },
  manageControlPanel: {
    id: 'manageControlPanel',
    name: 'Manage control panel',
    enabled: false,
    tooltip: '',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    dropdownOptions: controlPanel.enums.map((x) => x.value),
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value],
    parent: parentSettings.gpo.id,
  },
  freezeExclusions: {
    id: 'freezeExclusions',
    name: 'Freeze exclusions',
    enabled: true,
    tooltip: '',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    requestedCapabilities: [
      planCapabilities.UWF.value,
    ],
    parent: parentSettings.freezeSection.id,
  },
  cleanDownloadsFolder: {
    id: 'cleanDownloadsFolder',
    name: 'Downloads',
    tooltip: '',
    setupTime: false,
    canSwitch: false,
    withoutSwitch: true,
    isAdvancedPlan: false,
    available: true,
    adjustableTime: true,
    defaultFrequency: frequencies.OFF.value.id,
    minFrequency: frequencies.OFF.value.minutes,
    maxFrequency: frequencies.ONE_YEAR.value.minutes,
    typeOfDelete: parametersToDeleteFiles.PERMANENTLY_DELETE.value.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
    ],
    parent: parentSettings.cleanFoldersSection.id,
  },
  cleanDocumentsFolder: {
    id: 'cleanDocumentsFolder',
    name: 'Documents',
    tooltip: '',
    setupTime: false,
    canSwitch: false,
    withoutSwitch: true,
    isAdvancedPlan: false,
    available: true,
    adjustableTime: true,
    defaultFrequency: frequencies.OFF.value.id,
    minFrequency: frequencies.OFF.value.minutes,
    maxFrequency: frequencies.ONE_YEAR.value.minutes,
    typeOfDelete: parametersToDeleteFiles.PERMANENTLY_DELETE.value.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
    ],
    parent: parentSettings.cleanFoldersSection.id,
  },
  cleanDesktopFolder: {
    id: 'cleanDesktopFolder',
    name: 'Desktop',
    tooltip: '',
    setupTime: false,
    canSwitch: false,
    withoutSwitch: true,
    isAdvancedPlan: false,
    available: true,
    adjustableTime: true,
    defaultFrequency: frequencies.OFF.value.id,
    minFrequency: frequencies.OFF.value.minutes,
    maxFrequency: frequencies.ONE_YEAR.value.minutes,
    typeOfDelete: parametersToDeleteFiles.PERMANENTLY_DELETE.value.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
    ],
    parent: parentSettings.cleanFoldersSection.id,
  },
  cleanPicturesFolder: {
    id: 'cleanPicturesFolder',
    name: 'Pictures',
    tooltip: '',
    setupTime: false,
    canSwitch: false,
    withoutSwitch: true,
    isAdvancedPlan: false,
    available: true,
    adjustableTime: true,
    defaultFrequency: frequencies.OFF.value.id,
    minFrequency: frequencies.OFF.value.minutes,
    maxFrequency: frequencies.ONE_YEAR.value.minutes,
    typeOfDelete: parametersToDeleteFiles.PERMANENTLY_DELETE.value.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
    ],
    parent: parentSettings.cleanFoldersSection.id,
  },
  cleanMusicFolder: {
    id: 'cleanMusicFolder',
    name: 'Music',
    tooltip: '',
    setupTime: false,
    canSwitch: false,
    withoutSwitch: true,
    isAdvancedPlan: false,
    available: true,
    adjustableTime: true,
    defaultFrequency: frequencies.OFF.value.id,
    minFrequency: frequencies.OFF.value.minutes,
    maxFrequency: frequencies.ONE_YEAR.value.minutes,
    typeOfDelete: parametersToDeleteFiles.PERMANENTLY_DELETE.value.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
    ],
    parent: parentSettings.cleanFoldersSection.id,
  },
  cleanVideosFolder: {
    id: 'cleanVideosFolder',
    name: 'Videos',
    tooltip: '',
    setupTime: false,
    canSwitch: false,
    withoutSwitch: true,
    isAdvancedPlan: false,
    available: true,
    adjustableTime: true,
    defaultFrequency: frequencies.OFF.value.id,
    minFrequency: frequencies.OFF.value.minutes,
    maxFrequency: frequencies.ONE_YEAR.value.minutes,
    typeOfDelete: parametersToDeleteFiles.PERMANENTLY_DELETE.value.id,
    requestedCapabilities: [
      planCapabilities.DEVICES_MANAGEMENT.value,
    ],
    parent: parentSettings.cleanFoldersSection.id,
  },
  // cleanRecycleBinFolder: {
  //   id: 'cleanRecycleBinFolder',
  //   name: 'Recycle bin',
  //   tooltip: '',
  //   setupTime: false,
  //   canSwitch: false,
  //   withoutSwitch: true,
  //   isAdvancedPlan: false,
  //   available: true,
  //   adjustableTime: true,
  //   defaultFrequency: frequencies.OFF.value.id,
  //   minFrequency: frequencies.OFF.value.minutes,
  //   maxFrequency: frequencies.ONE_YEAR.value.minutes,
  //   typeOfDelete: parametersToDeleteFiles.PERMANENTLY_DELETE.value.id,
  //   requestedCapabilities: [
  //     planCapabilities.DEVICES_MANAGEMENT.value,
  //   ],
  //   parent: parentSettings.cleanFoldersSection.id,
  // },
  manageCMD: {
    id: 'manageCMD',
    name: 'Manage CMD',
    enabled: false,
    tooltip: 'The device must be restarted after setting up this option.',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    dropdownOptions: controlPanel.enums.map((x) => x.value),
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value],
    parent: parentSettings.gpo.id,
  },
  managePowershell: {
    id: 'managePowershell',
    name: 'Manage PowerShell',
    enabled: false,
    tooltip: 'The device must be restarted after setting up this option.',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    dropdownOptions: controlPanel.enums.map((x) => x.value),
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value],
    parent: parentSettings.gpo.id,
  },
  manageSavePasswordInLanManager: {
    id: 'manageSavePasswordInLanManager',
    name: 'Manage save password in LAN Manager',
    enabled: false,
    tooltip: '',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    dropdownOptions: controlPanel.enums.map((x) => x.value),
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value],
    parent: parentSettings.gpo.id,
  },
  manageInstallMSI: {
    id: 'manageInstallMSI',
    name: 'Manage install MSI',
    enabled: false,
    tooltip: '',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    dropdownOptions: controlPanel.enums.map((x) => x.value),
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value],
    parent: parentSettings.gpo.id,
  },
  addDisclaimerOnSignIn: {
    id: 'addDisclaimerOnSignIn',
    name: 'Disclaimer on sign in',
    enabled: false,
    tooltip: 'The device must be restarted after setting up this option.',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value],
    parent: parentSettings.gpo.id,
  },
  manageAdminUser: {
    id: 'manageAdminUser',
    name: 'Manage administrator user',
    enabled: false,
    tooltip: '',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    dropdownOptions: controlPanel.enums.map((x) => x.value),
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value],
    parent: parentSettings.gpo.id,
  },
  lockDeviceAfterXTime: {
    id: 'lockDeviceAfterXTime',
    name: 'Manage auto-lock device after inactivity',
    enabled: false,
    tooltip: 'The device must be restarted after setting up this option.',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value],
    parent: parentSettings.gpo.id,
  },
  manageBlockLogin: {
    id: 'manageBlockLogin',
    name: 'Manage invalid login attempts',
    enabled: false,
    tooltip: 'The user will be blocked for 30 minutes after 3 invalid login attempts.',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    dropdownOptions: controlPanel.enums.map((x) => x.value),
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value],
    parent: parentSettings.gpo.id,
  },
  configureWallpaper: {
    id: 'configureWallpaper',
    name: 'Configure wallpaper',
    enabled: false,
    tooltip: 'The device must be restarted after setting up this option',
    setupTime: false,
    canSwitch: true,
    withoutSwitch: false,
    isAdvancedPlan: false,
    available: true,
    chooseWallpaper: true,
    requestedCapabilities: [planCapabilities.DEVICES_MANAGEMENT.value],
    parent: parentSettings.gpo.id,
  },
});
